// extracted by mini-css-extract-plugin
export var column = "JumbotronHivelocity__column__OMbfU";
export var flex = "JumbotronHivelocity__flex__HoHv9";
export var flexColumn = "JumbotronHivelocity__flexColumn__gwg9B";
export var gap1 = "JumbotronHivelocity__gap1__S83XK";
export var gap2 = "JumbotronHivelocity__gap2__FDzTz";
export var gap3 = "JumbotronHivelocity__gap3__TsR38";
export var gap4 = "JumbotronHivelocity__gap4__gwf9o";
export var gap5 = "JumbotronHivelocity__gap5__Gw8OH";
export var heading = "JumbotronHivelocity__heading__KIgnz";
export var icon = "JumbotronHivelocity__icon__g_j4c";
export var providerIcons = "JumbotronHivelocity__providerIcons__abrit";
export var row = "JumbotronHivelocity__row__I9Kt6";
export var subheading = "JumbotronHivelocity__subheading__SQgcT";
export var wrapper = "JumbotronHivelocity__wrapper__iDBUR";