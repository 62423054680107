// extracted by mini-css-extract-plugin
export var column = "JumbotronProviderIcons__column__He4bl";
export var flex = "JumbotronProviderIcons__flex__BJjHJ";
export var flexColumn = "JumbotronProviderIcons__flexColumn__Ubo5H";
export var gap1 = "JumbotronProviderIcons__gap1__DGMsC";
export var gap2 = "JumbotronProviderIcons__gap2__P5mGO";
export var gap3 = "JumbotronProviderIcons__gap3__oVsGC";
export var gap4 = "JumbotronProviderIcons__gap4__ujX81";
export var gap5 = "JumbotronProviderIcons__gap5__PaZ1H";
export var icon = "JumbotronProviderIcons__icon__lPnrx";
export var iconSmall = "JumbotronProviderIcons__iconSmall__oFGP8";
export var row = "JumbotronProviderIcons__row__MJfjN";
export var wrapper = "JumbotronProviderIcons__wrapper__g6DLx";