// extracted by mini-css-extract-plugin
export var anchor = "HivelocityBareMetal__anchor__Llipw";
export var backgroundImage = "HivelocityBareMetal__backgroundImage__JQUin";
export var cloudProviderIconsBareMetalSection = "HivelocityBareMetal__cloudProviderIconsBareMetalSection__qoSk6";
export var column = "HivelocityBareMetal__column__J5Vuq";
export var completeControlWithBareMetalSection = "HivelocityBareMetal__completeControlWithBareMetalSection__t3WBe";
export var detailsContent = "HivelocityBareMetal__detailsContent__m2V3s";
export var detailsSection = "HivelocityBareMetal__detailsSection__lTZh6";
export var detailsSidebar = "HivelocityBareMetal__detailsSidebar__Lw55z";
export var flex = "HivelocityBareMetal__flex__bbg4P";
export var flexColumn = "HivelocityBareMetal__flexColumn__EJzOF";
export var gap1 = "HivelocityBareMetal__gap1__wmVP3";
export var gap2 = "HivelocityBareMetal__gap2__FmGZW";
export var gap3 = "HivelocityBareMetal__gap3__daTW1";
export var gap4 = "HivelocityBareMetal__gap4__CbCW6";
export var gap5 = "HivelocityBareMetal__gap5__KfLWG";
export var jumbotronSection = "HivelocityBareMetal__jumbotronSection__Ejp6T";
export var map = "HivelocityBareMetal__map__lsz9e";
export var pricingAndConfigurationsSection = "HivelocityBareMetal__pricingAndConfigurationsSection__cQyRb";
export var pricingAndConfigurationsSectionContainer = "HivelocityBareMetal__pricingAndConfigurationsSectionContainer__c8AQk";
export var requestEstimateBareMetalSection = "HivelocityBareMetal__requestEstimateBareMetalSection__xQY_g";
export var row = "HivelocityBareMetal__row__k1aBj";
export var signUpTodaySection = "HivelocityBareMetal__signUpTodaySection__fhX3L";
export var sticky = "HivelocityBareMetal__sticky__sVWBo";
export var subNavigationBareMetalSection = "HivelocityBareMetal__subNavigationBareMetalSection__m8PAs";
export var whyBareMetalSection = "HivelocityBareMetal__whyBareMetalSection__hwREE";
export var wideContainer = "HivelocityBareMetal__wideContainer__H3FBM";
export var wrapper = "HivelocityBareMetal__wrapper__rz6_Y";