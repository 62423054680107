// extracted by mini-css-extract-plugin
export var bannerContainer = "HivelocityCaseStudyBanner__bannerContainer__hR4TP";
export var column = "HivelocityCaseStudyBanner__column__XMClN";
export var ctaWrapper = "HivelocityCaseStudyBanner__ctaWrapper__YJVNp";
export var description = "HivelocityCaseStudyBanner__description__Ye_PN";
export var flex = "HivelocityCaseStudyBanner__flex__TZiy_";
export var flexColumn = "HivelocityCaseStudyBanner__flexColumn__Mq8fX";
export var gap1 = "HivelocityCaseStudyBanner__gap1__rsotd";
export var gap2 = "HivelocityCaseStudyBanner__gap2__JhdbL";
export var gap3 = "HivelocityCaseStudyBanner__gap3__EEzu8";
export var gap4 = "HivelocityCaseStudyBanner__gap4__LJe78";
export var gap5 = "HivelocityCaseStudyBanner__gap5__D2BTI";
export var row = "HivelocityCaseStudyBanner__row__CcxiJ";
export var testimonialText = "HivelocityCaseStudyBanner__testimonialText__o6IbH";
export var testimonialWrapper = "HivelocityCaseStudyBanner__testimonialWrapper__IDMBF";
export var title = "HivelocityCaseStudyBanner__title__pN3fE";
export var wrapper = "HivelocityCaseStudyBanner__wrapper__z6lFM";
export var wrapperBackgroundImage = "HivelocityCaseStudyBanner__wrapperBackgroundImage__Z9Agd";
export var wrapperContainer = "HivelocityCaseStudyBanner__wrapperContainer__voR69";