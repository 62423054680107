// extracted by mini-css-extract-plugin
export var column = "HivelocityServicesOverview__column__MCfrM";
export var flex = "HivelocityServicesOverview__flex__Bmmxf";
export var flexColumn = "HivelocityServicesOverview__flexColumn__CtFH3";
export var gap1 = "HivelocityServicesOverview__gap1___AgR3";
export var gap2 = "HivelocityServicesOverview__gap2__lPbJ7";
export var gap3 = "HivelocityServicesOverview__gap3__yNVKw";
export var gap4 = "HivelocityServicesOverview__gap4__smCPG";
export var gap5 = "HivelocityServicesOverview__gap5__WCUZu";
export var row = "HivelocityServicesOverview__row__zpKCU";
export var service = "HivelocityServicesOverview__service__GOVXB";
export var serviceDetails = "HivelocityServicesOverview__serviceDetails__Oaic3";
export var servicesOverview = "HivelocityServicesOverview__servicesOverview__MKcke";